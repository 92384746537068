































import { Component, Vue } from 'vue-property-decorator';
import { TelegramIcon } from 'vue-simple-icons';

@Component({
  components: {
    TelegramIcon,
  },
})
export default class FooterBar extends Vue {
  private socials: Array<{ icon: string, link: string}> = [
    // { icon: 'mdi-si-telegram', link: 'https://t.me/iivdev' },
    { icon: 'mdi-discord', link: 'https://discord.gg/gK99v8j7' },
  ]
}
