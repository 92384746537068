












import { Component, Mixins } from 'vue-property-decorator';
import ChainUtilityMixin from '@/components/Utilities/ChainUtilityMixin';
import Step1Disclamer from './Steps/Step1_Disclamer.vue';
import Step2Prerequisites from './Steps/Step2_Prerequisites.vue';
import Step3StakingTab from './Steps/Step3_StackingTab.vue';
import Step4NominatorSetup1 from './Steps/Step4_SetupNominator_1.vue';
import Step5NominatorSetup2 from './Steps/Step5_SetupNominator_2.vue';
import Step6SignAndSubmit from './Steps/Step6_SignAndSubmit.vue';

@Component({
  components: {
    Step1Disclamer,
    Step2Prerequisites,
    Step3StakingTab,
    Step4NominatorSetup1,
    Step5NominatorSetup2,
    Step6SignAndSubmit,
  },
})
export default class StakingGuideView extends Mixins(ChainUtilityMixin) {
  private step = 1;
}
