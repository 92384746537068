










import { Component, Vue } from 'vue-property-decorator';
import api, { EthereumwisdomCoinData } from '../../api';

@Component({})
export default class MarketBanner extends Vue {
  private data: Array<EthereumwisdomCoinData> = [];

  private btcPrice = 0;

  private dataInterval = -1;

  public updateData(): void {
    api.getMarketData()
      .then((data: Array<EthereumwisdomCoinData>) => {
        this.btcPrice = data.filter((c) => c.coin === 'BTC')[0].price.USD;
        this.data = data.filter((c) => c.coin !== 'BTC');
      });
  }

  public created(): void {
    this.dataInterval = setInterval(this.updateData, 30000) as unknown as number;
    this.updateData();
  }

  public destroyed(): void {
    clearInterval(this.dataInterval);
  }
}
