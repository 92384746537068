
























import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import iiv from '@/types.d';

@Component({})
export default class Members extends Vue {
  @Prop({ required: true }) readonly member!: iiv.Member

  public getAvatarPath(): string {
    const avatar = _.get(this.member, 'avatar', 'none.png');
    return `/avatars/${avatar}`;
  }

  private revealP = false;

  private revealK = false;

  private rank = 1;
}
