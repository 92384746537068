import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
class StepMixin extends Vue {
  // Active Step! linked with v-model
  @Prop({ required: true }) readonly value!: number

  get activeStep(): number {
    return this.value;
  }

  set activeStep(step: number) {
    this.$emit('input', step);
  }
}
export default StepMixin;
