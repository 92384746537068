import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VueTyperPlugin from 'vue-typer';
import Fragment from 'vue-fragment';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueTyperPlugin);
Vue.use(Fragment.Plugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    // store.dispatch('loadData');
    store.dispatch('newLoadData');
  },
  render: (h) => h(App),
}).$mount('#app');
