

















import { Component, Prop, Mixins } from 'vue-property-decorator';
import StepMixin from './StepMixin';

@Component({
  components: {
  },
})
export default class GenericStep extends Mixins(StepMixin) {
  @Prop({ required: true }) readonly step!: number

  @Prop({ required: false, default: false }) readonly final!: boolean;
}
