import axios, { Method, AxiosRequestConfig } from 'axios';
// import { json } from 'express';
import _ from 'lodash';
// import EventSource from 'eventsource';

const { hostname } = window.location;
const port = process.env.VUE_APP_SRV_PORT || 3000;

let baseUrl = `http://${hostname}:${port}`;
let urlApi = `http://${hostname}:${port}/api`;

if (process.env.NODE_ENV === 'production') {
  baseUrl = '/';
  urlApi = '/api';
}

const clientBase = axios.create({
  baseURL: baseUrl,
  responseType: 'text',
});

const clientApi = axios.create({
  baseURL: urlApi,
  responseType: 'json',
});

const executeBase = (method: Method, resource: string, data?: Record<string, unknown>) => clientBase(
  { method, url: resource, data },
).then((req: { data: string }) => req.data);

const executeApi = (
  method: Method,
  url: string,
  data?: unknown,
  moreConfig?: AxiosRequestConfig,
) => {
  let config = { method, url, data };
  if (moreConfig) {
    config = _.assign(config, moreConfig);
  }
  return clientApi(config).then((req: { data: Record<string, unknown> }) => req.data);
};

export interface ContactMessageData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export interface DonationMessageData {
  value: number;
  memo: string;
}

export interface EthereumwisdomCoinData {
  coin: string;
  name: string;
  price: {
      BTC: number;
      USD: number;
      EUR: number;
  };
  change: number;
}

const validCoins = ['DOT', 'KSM', 'PCX', 'LINK', 'FIS', 'PHA', 'GLMR', 'MOVR', 'ASTR', 'SDN', 'BTC'];

export default {
  async getData(): Promise<string> {
    return executeBase('GET', '/data/data.yaml');
  },

  async getMarketData(): Promise<Array<EthereumwisdomCoinData>> {
    return executeBase('GET', `https://ethereumwisdom.com/data/summary_all.json?v=${Math.random()}`)
      .then((data) => data as unknown as Array<EthereumwisdomCoinData>)
      .then((data) => data.filter((c) => validCoins.indexOf(c.coin) !== -1));
  },

  /**
   * Telegram API
   */
  async sendContactNotification(data: ContactMessageData): Promise<Record<string, unknown>> {
    return executeApi('POST', '/contact', data);
  },
};
