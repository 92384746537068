



















import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';

@Component({})
export default class CopyButton extends Vue {
  @Prop({ required: true }) readonly textToCopy!: string

  @Prop({ required: false }) readonly buttonIcon!: string

  @Prop({ required: false }) readonly buttonText!: string

  private showResult = false;

  private isSuccess = false;

  // eslint-disable-next-line class-methods-use-this
  public onCopy(): void {
    this.isSuccess = true;
    this.showResult = true;
    _.delay(() => { this.showResult = false; }, 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  public onCopyError(): void {
    this.isSuccess = false;
    this.showResult = true;
    _.delay(() => { this.showResult = false; }, 1000);
  }
}
