


















import { Component, Mixins } from 'vue-property-decorator';
import ChainUtilityMixin from '@/components/Utilities/ChainUtilityMixin';

@Component({
  components: {
  },
})
export default class ChainSelector extends Mixins(ChainUtilityMixin) {
  private setChain(chain: string): void {
    if (this.chain === chain) { return; }
    this.$emit('selected', chain);
  }
}
