
































import { Component, Vue } from 'vue-property-decorator';
import Member from './Member.vue';

@Component({
  components: {
    Member,
  },
})
export default class Info extends Vue {
  private height = 300;
}
