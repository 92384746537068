// user-module.ts
import {
  VuexModule, Module, Mutation, Action,
} from 'vuex-class-modules';
// import store from '../index';

@Module
class UiModule extends VuexModule {
  // state
  darkMode = true;

  // mutations
  @Mutation
  setDarkMode(mode: boolean): void {
    this.darkMode = mode;
  }

  // actions
  @Action
  async toggleDarkMode(): Promise<void> {
    this.setDarkMode(!this.darkMode);
  }
}

export default UiModule;

// const m = new UiModule({ store, name: 'ui' });
// export default m;
