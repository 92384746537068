
























import { Component, Vue } from 'vue-property-decorator';
import Member from './Member.vue';

@Component({
  components: {
    Member,
  },
})
export default class MainHeader extends Vue {
  private headerTexts: Array<string> = [
    'Automatic Payouts',
    'Ledger Friendly',
    'Kusama Validators',
    'Polkadot Validators',
  ]
}
