













import { Component, Mixins } from 'vue-property-decorator';
import ChainUtilityMixin from '@/components/Utilities/ChainUtilityMixin';
import CopyButton from '@/components/Utilities/CopyButton.vue';
import { State } from 'vuex-class';
import iiv from '@/types.d';
import { get } from 'lodash';
import Member from '../Home/Member.vue';

@Component({
  components: {
    Member, CopyButton,
  },
})
export default class AddressList extends Mixins(ChainUtilityMixin) {
  @State('projects') projects!: iiv.RootState['projects']

  get project(): iiv.Project {
    return get(this.projects, this.chain);
  }
}
