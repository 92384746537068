import { render, staticRenderFns } from "./Step1_Disclamer.vue?vue&type=template&id=007ecbea&lang=pug&"
import script from "./Step1_Disclamer.vue?vue&type=script&lang=ts&"
export * from "./Step1_Disclamer.vue?vue&type=script&lang=ts&"
import style0 from "./Step1_Disclamer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle})
