/* eslint-disable object-curly-newline */
import Vue from 'vue';
// eslint-disable-next-line import/no-named-default
import { createLogger, default as Vuex } from 'vuex';
import YAML from 'yaml';
import { forEach, keyBy, noop } from 'lodash';
import iiv, { RootState } from '@/types.d';
import api from '../api';
import UiModule from './modules/ui';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: true,
    // members: Array<iiv.Member>(),
    members: {},
    // projects: Array<iiv.Project>(),
    projects: {},
  },
  mutations: {
    setLoading(state, { value }) {
      state.loading = value ?? true;
    },
    setMembers(state, { members }) {
      if (members) {
        state.members = { ...state.members, ...members };
      }
    },
    setProjects(state, { projects }) {
      if (projects) {
        state.projects = { ...state.projects, ...projects };
      }
    },
  },
  actions: {
    async newLoadData(context) {
      context.commit('setLoading', { value: true });
      const data: iiv.YamlData = YAML.parse(await api.getData());
      const members = keyBy(data.members, 'name');
      const projects = keyBy(data.projects, 'name');

      // Add projects information to members
      forEach(projects, (p) => {
        forEach(p.validators, (v) => {
          v.member.projects.push(p.name);
        });
      });

      context.commit('setMembers', { members });
      context.commit('setProjects', { projects });
      context.commit('setLoading', { value: false });
    },
  },
  modules: {
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [process.env.NODE_ENV !== 'production' ? createLogger() : noop],
});

export const uiModule = new UiModule({ store, name: 'ui' });

export default store;
