


















import { Component, Mixins, Prop } from 'vue-property-decorator';
import ChainUtilityMixin from '@/components/Utilities/ChainUtilityMixin';
import CopyButton from '@/components/Utilities/CopyButton.vue';
import { State } from 'vuex-class';
import iiv from '@/types.d';
import { get } from 'lodash';
import Member from '../Home/Member.vue';

@Component({
  components: {
    Member, CopyButton,
  },
})
export default class ProjectBadge extends Mixins(ChainUtilityMixin) {
  @State('projects') projects!: iiv.RootState['projects']

  @Prop({ required: true }) readonly selected!: boolean

  get project(): iiv.Project {
    return get(this.projects, this.chain);
  }

  public getLogoPath(): string {
    const avatar = get(this.project, 'logo', 'none.png');
    return `/data/logos/${avatar}`;
  }
}
