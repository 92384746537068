import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
class ChainUtilityMixin extends Vue {
  @Prop({ required: true }) readonly chain!: string

  get isPolkadot(): boolean {
    return this.chain === 'Polkadot';
  }

  get isKusama(): boolean {
    return this.chain === 'Kusama';
  }
}
export default ChainUtilityMixin;
