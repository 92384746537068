


































import { Component, Mixins } from 'vue-property-decorator';
import ChainUtilityMixin from '@/components/Utilities/ChainUtilityMixin';
import GenericStep from './GenericStep.vue';
import StepMixin from './StepMixin';

@Component({
  components: {
    GenericStep,
  },
})
export default class Step1Disclamer extends Mixins(ChainUtilityMixin, StepMixin) {
}
