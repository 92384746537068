









import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import iiv from '@/types.d';
import Member from './Member.vue';

@Component({
  components: {
    Member,
  },
})
export default class Members extends Vue {
  @State('members') members!: iiv.RootState['members']
}
