


















































import { Component, Vue } from 'vue-property-decorator';
import api from '../api';

export type VForm = Vue & {
  validate: () => boolean;
  reset: () => void;
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
})
export default class ContactModal extends Vue {
  private dialog = false;

  private valid = false;

  private name = '';

  private email = '';

  private emailRules = [
    (v: string) => !!v || 'A mail is required',
    (v: string) => emailRegex.test(v) || 'E-mail must be valid',
  ];

  private subject = '';

  private message = '';

  private messageRules = [
    (v: string) => !!v || 'A message is required',
  ];

  private showSuccess = false;

  private showError = false;

  public reset(): void {
    (this.$refs.contactForm as VForm).reset();
    this.showSuccess = false;
    this.showError = false;
  }

  public async submit(): Promise<void> {
    const data = {
      name: this.name,
      email: this.email,
      subject: this.subject,
      message: this.message,
    };
    const res = await api.sendContactNotification(data);
    if (res && res.sent === true) {
      // Alarm ok
      this.showSuccess = true;
      this.showError = false;
    } else {
      // Alarm ko
      this.showSuccess = false;
      this.showError = true;
    }
  }
}
