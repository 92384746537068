







import { Component, Vue } from 'vue-property-decorator';
import ChainSelector from '@/components/StakingGuide/ChainSelector.vue';
import StakingGuide from '@/components/StakingGuide/StakingGuide.vue';
import _ from 'lodash';

@Component({
  components: {
    ChainSelector, StakingGuide,
  },
})
export default class StakingGuideView extends Vue {
  private readonly SupportedStakingGuides = ['Polkadot', 'Kusama'];

  get chain(): string {
    return _.capitalize(_.get(this.$route.params, 'chain', this.SupportedStakingGuides[0]));
  }

  mounted(): void {
    const chainParam = _.capitalize(_.get(this.$route.params, 'chain'));
    if (!_.includes(this.SupportedStakingGuides, chainParam)) {
      this.$router.push({
        name: 'StakingGuideView',
        params: {
          chain: _.toLower(this.SupportedStakingGuides[0]),
        },
      });
    }
  }

  private onChainSelection(chain: string): void {
    this.$router.push({ name: 'StakingGuideView', params: { chain: _.toLower(chain) } });
  }
}
