
















import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import AddressList from '@/components/Utilities/AddressList.vue';
import ProjectBadge from '@/components/Utilities/ProjectBadge.vue';
import iiv from '@/types.d';
import { orderBy, values } from 'lodash';
import Member from './Member.vue';

@Component({
  components: {
    Member, AddressList, ProjectBadge,
  },
})
export default class Projects extends Vue {
  @State('projects') projects!: iiv.RootState['projects']

  private selectedChain = 'Polkadot';

  get orderedProjects(): Array<iiv.Project> {
    return orderBy(values(this.projects), (p) => p.validators?.length, 'desc');
  }
}
