












import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/components/Home/MainHeader.vue';
import Info from '@/components/Home/Info.vue';
import Projects from '@/components/Home/Projects.vue';
import MembersGrid from '@/components/Home/MembersGrid.vue';
import MarketBanner from '@/components/Home/MarketBanner.vue';

@Component({
  components: {
    MainHeader, Info, Projects, MembersGrid, MarketBanner,
  },
})
export default class HomeView extends Vue {}
