














import { Component, Vue } from 'vue-property-decorator';
import { uiModule } from '../store';

@Component({})
export default class ThemeSelector extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get darkMode(): boolean {
    return uiModule.darkMode;
  }

  // eslint-disable-next-line class-methods-use-this
  public toggleDarkMode(): void {
    uiModule.toggleDarkMode();
    this.$vuetify.theme.dark = uiModule.darkMode;
  }
}
